import bombeiro from "./bombeiro.jpeg";
import cozinha from "./cozinha.jpeg";
import logistica from "./logistico.jpeg";
import medica from "./medico.jpeg";
import cientista from "./cientista.jpeg";
import about from "../persona/About.png";
import Trabalho from "../persona/trabalho.png";
import logo from "../logo/sinergia azul.avif";
import logobranco from '../logo/sinergia branco.png';
import Selo from "../selo/Selo de Segurança.png";
import cliente1 from "../cliente/Adriana Camargo.png";
import cliente2 from "../cliente/Gabriella Mendes.png";
import cliente3 from "../cliente/Mariana.png";
import cliente4 from "../cliente/Rodrigo.png";
import BlogImage1 from '../blog/tendencia.jpeg';
import BlogImage2 from '../blog/trabalhista.jpeg';
import BlogImage3 from '../blog/sucesso.jpeg';
import valores from '../Banner/Valores/valores.jpeg';
import missao from '../Banner/Valores/missão.jpeg';

// Importing company images
import empresa1 from "../empresas/1_20241017_101638_0000.png";
import empresa2 from "../empresas/2_20241017_101638_0001.png";
import empresa3 from "../empresas/3_20241017_101638_0002.png";
import empresa4 from "../empresas/4_20241017_101638_0003.png";
import empresa5 from "../empresas/5_20241017_101638_0004.png";
import empresa6 from "../empresas/6_20241017_101638_0005.png";
import empresa7 from "../empresas/7_20241017_101638_0006.png";
import empresa8 from "../empresas/8_20241017_101638_0007.png";
import empresa9 from "../empresas/9_20241017_101638_0008.png";
import empresa10 from "../empresas/10_20241017_101638_0009.png";
import empresa11 from "../empresas/11_20241017_101638_0010.png";
import empresa12 from "../empresas/12_20241017_101638_0011.png";
import empresa13 from "../empresas/13_20241017_101638_0012.png";
import empresa14 from "../empresas/14_20241017_101638_0013.png";
import empresa15 from "../empresas/15_20241017_101638_0014.png";
import empresa16 from "../empresas/16_20241017_101638_0015.png";
import empresa17 from "../empresas/17_20241017_101639_0016.png";
import empresa18 from "../empresas/18_20241017_101639_0017.png";
import empresa19 from "../empresas/19_20241017_101639_0018.png";


import luizFelipe from "../equipe/Luiz Felipe.jpeg"; // Substitua pelo nome do arquivo correto
import robertaSilva from "../equipe/Roberta Silva .jpeg"; // Substitua pelo nome do arquivo correto
import rayaneSantos from "../equipe/Rayane Santos .jpeg"; // Substitua pelo nome do arquivo correto
import jenniferPallone from "../equipe/Jennifer Pallone.jpeg"; // Substitua pelo nome do arquivo correto
import brunaAraujo from "../equipe/Bruna Araujo.jpeg"; // Substitua pelo nome do arquivo correto
import marianaHohana from "../equipe/Mariana Hohana.jpeg"; // Substitua pelo nome do arquivo correto
import MickaelCypriano from "../equipe/mickael.jpeg"; // Substitua pelo nome do arquivo correto
import FelipeLima from '../equipe/luiz_felipe.jpeg';


import Label1 from  '../carrocel/Problemas_20240914_145228_0000.png'
import Label2 from  '../carrocel/Problemas_20240914_145228_0001.png'
import Label3 from  '../carrocel/Problemas_20240914_145228_0002.png'
import Label4  from  '../carrocel/desconto no pix_20240914_151008_0000 (1).png';

const images = {
  bombeiro,
  cozinha,
  logistica,
  medica,
  cientista,
  about,
  Trabalho,
  logo,
  logobranco,
  Selo,
  cliente1,
  cliente2,
  cliente3,
  cliente4,
  BlogImage1,
  BlogImage2,
  BlogImage3,
  valores,
  missao,
  empresa1,
  empresa2,
  empresa3,
  empresa4,
  empresa5,
  empresa6,
  empresa7,
  empresa8,
  empresa9,
  empresa10,
  empresa11,
  empresa12,
  empresa13,
  empresa14,
  empresa15,
  empresa16,
  empresa17,
  empresa18,
  empresa19,
  luizFelipe,
  robertaSilva,
  rayaneSantos,
  jenniferPallone,
  brunaAraujo,
  marianaHohana,
  MickaelCypriano,
  FelipeLima,
  Label1,
  Label2,
  Label3,
  Label4,
};

export default images;

