import React from 'react';
import '../css/TrabalheConosco.css';

const jobListings = [
  {
    id: 1,
    title: 'Analista de Recursos Humanos',
    location: 'São Paulo, SP',
  },
  {
    id: 2,
    title: 'Desenvolvedor Front-end',
    location: 'Remoto',
  },
  {
    id: 3,
    title: 'Gerente de Projetos',
    location: 'Rio de Janeiro, RJ',
  },
  // Adicionar mais vagas conforme necessário
];

const TrabalheConosco = () => {
  return (
    <div className="trabalhe-conosco" id='trabalhe-conosco'>
      <h1 className="trabalhe-conosco__title">Vagas Disponíveis</h1>
      <div className="trabalhe-conosco__job-carousel">
        {jobListings.map((job) => (
          <div key={job.id} className="trabalhe-conosco__job-card">
            <h3 className="trabalhe-conosco__job-title">{job.title}</h3>
            <p className="trabalhe-conosco__job-location">{job.location}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrabalheConosco;
