// src/components/Contato.js
import React, { useState } from 'react';
import '../css/Contato.css'; // Mantenha o CSS existente
import imagens from '../shared/Banner/import'; // Mantenha as imagens

const Contato = () => {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleWhatsAppMessage = (e) => {
    e.preventDefault();
    const phoneNumber = '5511998879991'; // Substitua pelo número de telefone do WhatsApp desejado
    const text = `Nome: ${name}%0aAssunto: ${subject}%0aMensagem: ${message}`;
    const url = `https://wa.me/${phoneNumber}?text=${text}`;
    window.open(url, '_blank'); // Abre o WhatsApp em uma nova aba
  };

  return (
    <div className="contato" id='contato'>
      <h1 className="contato__title">Contato</h1>
      <div className="contato__container">
        <div className="contato__image-container">
          <img
            src={imagens.Trabalho}
            alt="Imagem relacionada ao contato"
            className="contato__image"
          />
        </div>

        <form className="contato__form" onSubmit={handleWhatsAppMessage}>
          <h2 className="contato__form-title">Entre em Contato</h2>

          <label htmlFor="name">Nome:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="contato__input" placeholder='Digite teu nome '
          />

          <label htmlFor="subject">Assunto:</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            className="contato__input" placeholder='Digite o assunto que deseja aqui exemplo vaga, contato ou proosta'
          />

          <label htmlFor="message">Mensagem:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="contato__textarea" placeholder='Digite sua sua mensagem aqui'
          />

          <button type="submit" className="contato__submit">
            Fale Conosco
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contato;
