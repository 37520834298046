import React, { useState } from "react";
import "../css/MainComponents.css"; // Importar o CSS para o cabeçalho
import images from "../shared/Banner/import"; // Corrigir a importação se necessário
import Slider from "react-slick"; // Importar o Slider
import "slick-carousel/slick/slick.css"; // Importar CSS do Slick
import "slick-carousel/slick/slick-theme.css"; // Importar tema do Slick
import DepoimentoScreen from "../screen/DepoimentoScreen";
import BlogLayout from "../screen/BlogLayout";
import TrabalheConosco from "../screen/TrabalheScreen";
import Contato from "../screen/ContatoScreen";
import TeamSection from "../screen/team";
import Carousel from "./carrocelComponent";

const MainComponents = () => {
  const [isExpanded, setIsExpanded] = useState(false); // Estado para controlar a expansão do texto

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded); // Alterna o estado de expansão
  };

  const carouselSettings = {
    dots: true, // Mostrar pontos de navegação
    infinite: true, // Permitir looping
    speed: 500, // Velocidade da transição
    slidesToShow: 1, // Quantidade de slides mostrados
    slidesToScroll: 1, // Quantidade de slides a serem rolados por vez
    autoplay: true, // Ativar autoplay
    autoplaySpeed: 3000, // Tempo de espera entre as transições
    arrows: true, // Sem setas de navegação
    fade: true, // Ativar transição suave
  };

  const partnerCarouselSettings = {
    infinite: true, // Loop infinito
    speed: 5000, // Velocidade da rotação
    slidesToShow: 5, // Quantidade de empresas mostradas por vez
    slidesToScroll: 1, // Quantidade de slides a serem rolados por vez
    autoplay: true, // Autoplay ativado
    autoplaySpeed: 2000, // Troca de slide a cada 2 segundos
    arrows: false, // Sem setas de navegação
    cssEase: "linear", // Roda suavemente e continuamente
    pauseOnHover: false, // Continua rodando mesmo ao passar o mouse
  };

  const serviceCarouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Mude para 3 para mostrar 3 cards ao mesmo tempo
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Exibir 2 cards em telas menores
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Exibir 1 card em telas ainda menores
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <main className="main">
      <Slider {...carouselSettings}>
        <div>
          <img
            src={images.bombeiro}
            alt="Bombeiro"
            className="responsive-image"
          />
        </div>
        <div>
          <img
            src={images.cozinha}
            alt="Cozinha"
            className="responsive-image"
          />
        </div>
        <div>
          <img
            src={images.cientista}
            alt="Cientista"
            className="responsive-image"
          />
        </div>
        <div>
          <img
            src={images.logistica}
            alt="Logística"
            className="responsive-image"
          />
        </div>
        <div>
          <img src={images.medica} alt="Médica" className="responsive-image" />
        </div>
      </Slider>

      {/* Seção Sobre Nós */}
      <section className="about-us" id="sobre">
        <div className="about-text">
          <h2>Por que nosso nome é Sinergia?</h2>
          <p>
            Na SinergiaRH, acreditamos que "sinergia" é um conceito central e
            vital para a nossa missão e práticas diárias, o que representa a
            combinação harmoniosa de elementos que, ao se unirem, produzem um
            efeito maior do que a soma das suas partes individuais. Aqui está
            como aplicamos esse conceito em nossas atividades:
          </p>
          <p>
            Colaboração Eficiente: Trabalhamos em estreita colaboração com
            nossos clientes, entendendo suas necessidades e objetivos, para
            desenvolver soluções de RH que realmente façam a diferença.
            Alinhamento Estratégico: Alinhamos as práticas de recursos humanos
            com os objetivos estratégicos das empresas, garantindo que todos os
            esforços estejam direcionados para o crescimento e sucesso
            organizacional. Essa parceria resulta em processos mais eficientes e
            eficazes.
          </p>
        </div>
        <div className="about-image">
          <img
            src={images.about}
            alt="Imagem representativa"
            className="responsive-image"
          />{" "}
          {/* Substitua suaImagem pela imagem que deseja mostrar */}
        </div>
      </section>

      {/* Seção de Apresentação dos Membros */}
      <TeamSection />

      {/* Carrossel de Empresas Parceiras */}
      <section className="partners-carousel">
        <h2>Empresas Parceiras</h2>
        <Slider {...partnerCarouselSettings}>
          <div>
            <img
              src={images.empresa1}
              alt="Parceiro 1"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa2}
              alt="Parceiro 2"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa3}
              alt="Parceiro 3"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa5}
              alt="Parceiro 5"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa6}
              alt="Parceiro 6"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa7}
              alt="Parceiro 7"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa8}
              alt="Parceiro 8"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa9}
              alt="Parceiro 9"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa10}
              alt="Parceiro 10"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa11}
              alt="Parceiro 11"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa12}
              alt="Parceiro 12"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa13}
              alt="Parceiro 13"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa14}
              alt="Parceiro 14"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa15}
              alt="Parceiro 15"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa16}
              alt="Parceiro 16"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa17}
              alt="Parceiro 17"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa18}
              alt="Parceiro 18"
              className="partner-image"
            />
          </div>
          <div>
            <img
              src={images.empresa19}
              alt="Parceiro 19"
              className="partner-image"
            />
          </div>
          {/* Adicione mais empresas parceiras conforme necessário */}
        </Slider>
      </section>

      {/* Seção Nossos Valores e Nossa Missão */}
      <section className="values-mission-section">
        <div className="values-mission-container">
          {/* Seção Nossos Valores */}
          <div className="values-section">
            <img src={images.valores} alt="nossos valores" />
          </div>

          {/* Seção Nossa Missão */}
          <div className="mission-section">
            <img src={images.missao} alt="nossa missão" />
          </div>
        </div>
      </section>

      {/* Carrossel de Serviços */}
      <section className="services-section" id="servicos">
        <h2>Nossos Serviços</h2>
        <Slider {...serviceCarouselSettings}>
          <div className="service-card">
            <h3>Recrutamento e Seleção</h3>
            <p>
              A SinergiaRH oferece serviços completos de recrutamento e seleção
              de profissionais, buscando talentos qualificados que atendam às
              necessidades específicas de cada cliente. Com um processo ágil e
              eficaz, garantimos a identificação dos melhores perfis para cargos
              de todos os níveis hierárquicos.
            </p>
          </div>

          <div className="service-card">
            <h3>Terceirização de Mão de Obra</h3>
            <p>
              Oferecemos soluções de terceirização de mão de obra para diversos
              setores, permitindo que as empresas foquem em seu core business
              enquanto cuidamos da gestão dos profissionais terceirizados.
              Garantimos a contratação, treinamento e acompanhamento de
              colaboradores, assegurando eficiência e conformidade com as
              legislações trabalhistas.
            </p>
          </div>

          <div className="service-card">
            <h3>Consultoria em Recursos Humanos</h3>
            <p>
              A SinergiaRH presta consultoria estratégica em RH, ajudando
              empresas a aprimorarem seus processos de gestão de pessoas,
              políticas de benefícios, cultura organizacional e clima
              corporativo. Oferecemos soluções sob medida para melhorar a
              performance e engajamento dos colaboradores.
            </p>
          </div>

          <div className="service-card">
            <h3>Locação de Mão de Obra Temporária</h3>
            <p>
              Oferecemos soluções de locação de mão de obra temporária para
              demandas sazonais, projetos específicos ou substituições. Cuidamos
              de todo o processo de contratação, gestão e documentação dos
              temporários, proporcionando flexibilidade e agilidade para o
              negócio.
            </p>
          </div>

          {/* Adicione mais cards conforme necessário */}
        </Slider>
      </section>
      <DepoimentoScreen />

      <BlogLayout />
      <Carousel/>

      <TrabalheConosco />

      <Contato />
    </main>
  );
};

export default MainComponents;
