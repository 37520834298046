import React, { useState } from 'react';
import '../css/HeaderComponents.css'; 
import Logo from '../shared/logo/sinergia azul.avif'; 
// import SolicitarProposta from '../screen/PropostaScreen'; // Import the SolicitarProposta component

const HeaderComponents = () => {
  const [isOpen, setIsOpen] = useState(false); 
  const [isDialogOpen, setIsDialogOpen] = useState(false); 

  const toggleMenu = () => {
    setIsOpen(!isOpen); 
  };

  const openDialog = () => {
    setIsDialogOpen(true); 
  };

  const closeDialog = () => {
    setIsDialogOpen(false); 
  };

  // Função para fazer scroll suave para a seção desejada
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' }); // Rolar suavemente para a seção
      setIsOpen(false); // Fecha o menu após a seleção
    }
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={Logo} alt="Logo" />
      </div>
      <div className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="#sobre" onClick={() => scrollToSection('sobre')}>Sobre</a></li>
          <li><a href="#servicos" onClick={() => scrollToSection('servicos')}>Serviços</a></li>
          <li><a href="#depoimentos" onClick={() => scrollToSection('depoimentos')}>Depoimentos</a></li>
          <li><a href="#blog" onClick={() => scrollToSection('blog')}>Blog</a></li>
          <li><a href="#contato" onClick={() => scrollToSection('contato')}>Contato</a></li>
          <li><a href="#trabalhe-conosco" onClick={() => scrollToSection('trabalhe-conosco')}>Vagas</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default HeaderComponents;
