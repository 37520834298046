import React from 'react';
import Slider from 'react-slick';
import images from "../shared/Banner/import"; // Corrigir a importação se necessário
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/team.css';

const TeamSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const teamMembers = [
    {
      name: "Luiz Felipe",
      role: "Diretor Comercial",
      description:
        "Profissional com vasta experiência em gestão comercial, Luiz lidera as estratégias de vendas e relacionamento com clientes na SinergiaRH, garantindo o crescimento contínuo e a excelência nos serviços oferecidos.",
      img: images.luizFelipe,
    },
    // {
    //   name: "Felipe Lima",
    //   role: "Diretor de operações",
    //   description:
    //     "Diretor de Operações - Com vasta experiência em gestão de pessoas e projetos, Phil lidera as operações da SinergiaRH com foco em excelência e qualidade. Sua expertise em recrutamento e terceirização de mão de obra impulsiona o crescimento estratégico da empresa e satisfação dos clientes.",
    //   img: images.FelipeLima,
    // },
    {
      name: "Roberta Silva",
      role: "Coordenadora Administrativa Operacional",
      description:
        "Com uma sólida trajetória em gestão operacional, Rô coordena todas as operações administrativas da SinergiaRH, assegurando o funcionamento eficiente dos processos internos.",
      img: images.robertaSilva,
    },
    {
      name: "Rayane Santos",
      role: "Analista de Recrutamento e Seleção",
      description:
        "Atua na captação e seleção de talentos, o principal foco da Ray é conectar empresas a profissionais qualificados com foco em resultados assertivos.",
      img: images.rayaneSantos,
    },
    {
      name: "Jennifer Pallone",
      role: "Estagiária de Recrutamento e Seleção",
      description:
        "Em início de carreira, Jenny apoia os processos de recrutamento e seleção, contribuindo com entusiasmo e novas perspectivas para o time de RH da SinergiaRH.",
      img: images.jenniferPallone,
    },

    {
      name: "Mariana Hohana",
      role: "Comunicação e Propaganda",
      description:
        "Especialista em comunicação estratégica, Mari é responsável por desenvolver e coordenar as campanhas de comunicação e marketing da SinergiaRH, fortalecendo a imagem da empresa e aprimorando sua presença no mercado.",
      img: images.marianaHohana,
    },
    {
      name: "Mickael Cypriano",
      role: "Desenvolvedor Full Stack",
      description:
        "Profissional com ampla experiência em desenvolvimento de software, lidera projetos de tecnologia desde a concepção até a implementação, garantindo a integração perfeita entre front-end e back-end. Com habilidades em diversas linguagens de programação e frameworks.",
      img: images.MickaelCypriano,
    },
  ];

  return (
    <section className="team-section">
      <h2>Nossa Equipe</h2>
      <Slider {...settings}>
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <div className="image-container">
              <img src={member.img} alt={member.name} className="team-photo" />
            </div>
            <div className="text-container">
              <h3>{member.name}</h3>
              <h4>{member.role}</h4>
              <p>{member.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default TeamSection;
