import React from 'react';
import Slider from 'react-slick';
import imagens from '../shared/Banner/import';

const phoneNumber = '5511998879991'; // Número de telefone do WhatsApp com o código do país (55 para o Brasil)
const message = 'Preciso contratar um profissional';

const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

const images = [
  { src: imagens.Label1, link: whatsappLink },
  { src: imagens.Label2, link: whatsappLink },
  { src: imagens.Label3, link: whatsappLink },
];

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const styles = {
    carouselContainer: {
      width: '90%',
      margin: '0 auto',
      borderRadius: '10px',
      overflow: 'hidden',
    },
    imageContainer: {
      width: '100%',
      textAlign: 'center',
    },
    image: {
      width: '100%',
      height: 'auto',
      borderRadius: '10px',
      objectFit: 'cover',
    },
    dots: {
      position: 'absolute',
      bottom: '15px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    prevArrow: {
      fontSize: '30px',
      color: '#333',
      zIndex: 1,
    },
    nextArrow: {
      fontSize: '30px',
      color: '#333',
      zIndex: 1,
    },
  };

  return (
    <div style={styles.carouselContainer}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} style={styles.imageContainer}>
            <a href={image.link} target="_blank" rel="noopener noreferrer">
              <img src={image.src} alt={`Slide ${index + 1}`} style={styles.image} />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
